// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {updateExpiration, calculateDate} from './personal-access-token-summary'

on('change', '.js-default-token-expiration-select', function (event) {
  const selectMenu = event.target as HTMLSelectElement
  const selectedIndex = selectMenu.selectedIndex
  const selectedOption = selectMenu.children[selectedIndex]!
  const humanTextAttr = selectedOption.getAttribute('data-human-date')

  updateExpirationNoteAndCustomDateSelector(humanTextAttr, selectMenu.value)
})

on(
  'itemActivated',
  '.js-new-default-token-expiration-select',
  function (event) {
    const item = event.detail.item as HTMLElement

    const itemValue = item.querySelector('button')?.getAttribute('data-value') || null
    if (!itemValue) return
    const humanText = item.getAttribute('data-custom-limit')
      ? `The token will expire on ${calculateDate(itemValue)}`
      : item.getAttribute('data-human-date')

    updateExpirationNoteAndCustomDateSelector(humanText, itemValue)
    updateExpiration()
  },
  {capture: true},
)

on(
  'details-menu-selected',
  '.js-target-container',
  function (event) {
    resetTokenExpirationSelect()
    const relatedTarget = event.detail.relatedTarget as HTMLElement

    const expirationLimit = relatedTarget.getAttribute('data-fg-limit')
    const expirationLimitTarget = relatedTarget.getAttribute('data-fg-limit-target')
    if (!expirationLimit) return

    updateTokenExpirationField(Number(expirationLimit), expirationLimitTarget)
  },
  {capture: true},
)

function updateExpirationNoteAndCustomDateSelector(humanText: string | null, selectedValue: string | null) {
  const pickerWrapper = document.getElementById('custom-expires-wrapper') as HTMLElement
  const picker = document.querySelector('#custom-expires-wrapper .form-select') as HTMLInputElement
  const formElementWrapper = document.getElementById('token-expiration') as HTMLElement
  const expirationHelperNoteWrapper = document.getElementById('expiration-helper-note') as HTMLElement
  const expirationWarning = document.getElementById('no-expiration-warning') as HTMLElement
  const expirationNoteContent = expirationHelperNoteWrapper.querySelector('.note') as HTMLElement

  if (selectedValue && picker) {
    if (selectedValue === 'custom') {
      expirationHelperNoteWrapper.setAttribute('hidden', 'hidden')
      expirationWarning.setAttribute('hidden', 'hidden')
      pickerWrapper.removeAttribute('hidden')
    } else {
      pickerWrapper.setAttribute('hidden', 'hidden')
      picker.value = ''
      expirationHelperNoteWrapper.removeAttribute('hidden')
      formElementWrapper.classList.remove('errored')

      if (selectedValue === 'none') {
        expirationWarning.removeAttribute('hidden')
      } else {
        expirationWarning.setAttribute('hidden', 'hidden')
      }

      if (humanText) {
        expirationNoteContent.textContent = humanText
      }
    }
  }
}

function resetTokenExpirationSelect() {
  const template = document.querySelector('.js-new-default-token-expiration-select-template') as HTMLElement
  if (!template) return

  const selectContainer = document.querySelector('.js-new-default-token-expiration-select-container') as HTMLElement

  selectContainer.innerHTML = template.innerHTML
}

function updateTokenExpirationField(expirationLimit: number, target: string | null) {
  removeItemsOverLimit(expirationLimit)
  updateCustomOptionDescription(expirationLimit, target)
}

function removeItemsOverLimit(expirationLimit: number) {
  for (const item of document.querySelectorAll('.js-new-default-token-expiration-item')) {
    const itemValue = item.querySelector('button')?.getAttribute('data-value')
    if (!itemValue) continue

    if (itemValue === 'custom' && expirationLimit === 1) {
      // remove the custom field if the expiration limit is 1 day
      document
        .querySelector<HTMLButtonElement>(".js-new-default-token-expiration-item > button[data-value='custom']")
        ?.setAttribute('hidden', 'hidden')
      continue
    }

    // reset previously hidden items
    item.removeAttribute('hidden')

    if (expirationLimit > 0 && Number(itemValue) > expirationLimit) {
      item.setAttribute('hidden', 'hidden')
    }
  }
}

function updateCustomOptionDescription(expirationLimit: number, target: string | null) {
  if (expirationLimit > 0) {
    // update the custom max date option
    const date = new Date()
    date.setDate(date.getDate() + expirationLimit)
    const newCustomDateMaxDate = date.toISOString().split('T')[0] || ''
    const customDateField = document.querySelector<HTMLInputElement>('#user_programmatic_access_custom_expires_at')
    customDateField?.setAttribute('max', newCustomDateMaxDate)

    // is there a ready item that matches the limit?
    const item = document.querySelector(
      `.js-new-default-token-expiration-item > button[data-value='${expirationLimit}']`,
    )

    if (item) {
      const description = item.querySelector('.ActionListItem-description')

      if (description) {
        // eslint-disable-next-line i18n-text/no-en
        description.textContent = `Limit set by ${target}`
      }
    } else {
      // if there isn't, we will get the blank custom limit option and set the description and value to the limit

      const newCustomLimitItem = document.querySelector<HTMLElement>(
        '.js-new-default-token-expiration-item-custom-limit',
      )
      if (newCustomLimitItem) {
        newCustomLimitItem.removeAttribute('hidden')
        newCustomLimitItem.querySelector('button')?.setAttribute('data-value', `${expirationLimit}`)
        const limitItemLabel = newCustomLimitItem.querySelector('.ActionListItem-label')
        if (limitItemLabel) {
          limitItemLabel.textContent = `${expirationLimit} days`
        }
        const description = newCustomLimitItem.querySelector('.ActionListItem-description')

        if (description) {
          // eslint-disable-next-line i18n-text/no-en
          description.textContent = `Limit set by ${target}`
        }
      }
    }

    if (expirationLimit < 30) {
      const button = document.querySelector<HTMLButtonElement>(
        `.js-new-default-token-expiration-item > button[data-value='${expirationLimit}']`,
      )
      if (button) {
        button.click()
      }
    }

    // set the description for the custom option
    const customOptionDescription = document.querySelector(
      '.js-new-default-token-expiration-item > button[data-value="custom"] .ActionListItem-description',
    )
    if (customOptionDescription) customOptionDescription.textContent = `between 1 and ${expirationLimit} days`
  }
}
