import {debounce} from '@github/mini-throttle'
import {onInput} from '@github-ui/onfocus'

// Get the src attribute from the check target.
function getCheckTargetSrc(checkTarget: HTMLElement): string {
  const src = checkTarget.getAttribute('src')
  if (!src) return ''

  const link = document.createElement('a')
  link.href = src
  return link.href
}

// Input Event Handler.
async function handleInput(event: Event) {
  const field = event.target as HTMLInputElement
  const form = field.form!
  const inputs = form.querySelectorAll<HTMLInputElement>('.js-key-link-input')

  const formData = new FormData()
  for (const input of inputs) {
    // only append the radio button value if it's checked, otherwise ignore it completely
    if (input.type === 'radio') {
      if (input.checked) {
        formData.append(input.name, input.value)
      }
    } else {
      formData.append(input.name, input.value)
    }
  }

  const checkTarget = form.querySelector<HTMLElement>('.js-key-link-check')!
  const src = getCheckTargetSrc(checkTarget)
  const token = checkTarget.parentElement!.querySelector<HTMLInputElement>('.js-key-link-check-csrf')!
  const response = await fetch(src, {
    method: 'POST',
    mode: 'same-origin',
    body: formData,
    headers: {
      'Scoped-CSRF-Token': token.value,
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
  if (!response.ok) {
    checkTarget.hidden = true
    return
  }
  const preview = await response.text()

  checkTarget.innerHTML = preview
  checkTarget.hidden = false
}

onInput('.js-key-link-input', debounce(handleInput, 300))
