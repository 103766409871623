// PATs v2: Token summary display behavior

import {ToggleState, updateCollapsibleContainerStyles} from './personal-access-token-collapsible-containers'
import {PermissionType, RepoSelectionType, updateElText} from './personal-access-tokens'

const enum PermissionSummarySelector {
  Organization = '#js-organization-permission-summary',
  Repository = '#js-repository-permission-summary',
  User = '#js-user-permission-summary',
}

const enum CheckedPermissionsSelector {
  Organization = '[data-organization-permissions]',
  Repository = '[data-repository-permissions]',
  User = '[data-user-permissions]',
  Business = '[data-business-permissions]',
}

export function updateRepositoryCount() {
  const repoSelectorRadio = document.querySelector<HTMLInputElement>('.js-installation-repositories-radio:checked')
  const selectionType = repoSelectorRadio ? repoSelectorRadio.value : RepoSelectionType.None

  if (selectionType === RepoSelectionType.Selected) {
    const repositoryCount = document.querySelectorAll<HTMLInputElement>('.js-selected-repository-field').length
    updateElText('#token-summary [data-token-summary="repository-count"]', String(repositoryCount))
  } else {
    updateElText('#token-summary [data-token-summary="repository-count"]', selectionType)
  }
}

export function updatePermissionSummary(permissionType: string) {
  const permissionEls = selectedPermissionEls(permissionType)

  // update permission counts in label and summary if present
  updatePermissionCounts(permissionType, permissionEls.length)

  const summaryContainer = summaryContainerEl(permissionType)

  // integrations pages exit here because they don't have the summary container
  if (!summaryContainer) return

  const listEl = summaryContainer.querySelector<HTMLUListElement>('ul')!

  // clear out the permission summary
  for (const child of listEl.querySelectorAll<HTMLLIElement>('li')) {
    if (child.getAttribute('data-permission-template') === 'false') listEl.removeChild(child)
  }

  // add currently selected permissions to summary
  for (const permissionEl of permissionEls) {
    appendPermissionSummary(listEl, permissionEl)
  }

  // update summary style
  // catches case where summary was previously open but empty
  const toggleState = summaryContainer.hasAttribute('open') ? ToggleState.Open : ToggleState.Close
  updateCollapsibleContainerStyles(summaryContainer, toggleState)
}

export function updateExpiration() {
  const expirationField =
    document.querySelector<HTMLInputElement>(
      '.js-user-programmatic-access-form input[name="user_programmatic_access[default_expires_at]"]',
    ) || document.querySelector<HTMLSelectElement>('.js-default-token-expiration-select')
  if (!expirationField) return
  const expirationValue = expirationField.value

  if (expirationValue === 'none') {
    updateElText('#token-summary [data-token-summary="expiration"]', 'never.')
  } else if (expirationValue === 'custom') {
    const customDate = document.querySelector<HTMLInputElement>('#user_programmatic_access_custom_expires_at')!.value
    // Setting the time to midnight because otherwise the default of midnight UTC might output the prior date based on timezone offset
    const dateString = customDate ? formatDateString(new Date(`${customDate}T00:00:00`)) : 'an unselected custom date'
    updateElText('#token-summary [data-token-summary="expiration"]', `on ${dateString}.`)
  } else {
    const dateString = calculateDate(expirationValue)
    updateElText('#token-summary [data-token-summary="expiration"]', `on ${dateString}.`)
  }
}

function dataPermissionAttribute(permissionType: string) {
  if (permissionType === PermissionType.Organization) {
    return CheckedPermissionsSelector.Organization
  }
  if (permissionType === PermissionType.Repository) {
    return CheckedPermissionsSelector.Repository
  }
  if (permissionType === PermissionType.User) {
    return CheckedPermissionsSelector.User
  }
  if (permissionType === PermissionType.Business) {
    return CheckedPermissionsSelector.Business
  }

  return null
}

function summaryContainerSelector(permissionType: string) {
  if (permissionType === PermissionType.Organization) {
    return PermissionSummarySelector.Organization
  } else if (permissionType === PermissionType.Repository) {
    return PermissionSummarySelector.Repository
  } else if (permissionType === PermissionType.User) {
    return PermissionSummarySelector.User
  }

  return null
}

function summaryContainerEl(permissionType: string) {
  const selector = summaryContainerSelector(permissionType)
  if (!selector) return null

  return document.querySelector<HTMLDivElement>(selector)
}

function selectedPermissionEls(permissionType: string) {
  const dataSelector = dataPermissionAttribute(permissionType)
  if (!dataSelector) return []

  const selector = `${dataSelector}.js-permissions-selector [id^=integration_permission_]:checked:not([data-permission="none"]`
  return document.querySelectorAll<HTMLInputElement>(selector)
}

function updatePermissionCounts(permissionType: string, count: number) {
  // update permission count in permission label and summary if present
  updateElText(`[data-token-summary='${permissionType}-permission-count']`, String(count))

  // correctly pluralize summary
  const selector = `${summaryContainerSelector(permissionType)} [data-token-summary-plural-count]`

  if (count === 1) {
    updateElText(selector, '')
  } else {
    updateElText(selector, 's')
  }

  // show/hide permission label
  const label = document.querySelector<HTMLElement>(`${dataPermissionAttribute(permissionType)} .js-permission-label`)
  if (label) {
    count > 0 ? (label.hidden = false) : (label.hidden = true)
  }
}

function appendPermissionSummary(listEl: HTMLUListElement, permissionEl: HTMLInputElement) {
  const permissionName = permissionEl.closest('li')!.querySelector<HTMLElement>('strong')!.textContent
  const permissionLevel = permissionEl.parentElement!.querySelector('[data-filter-item-text]')!.textContent!.trim()
  const template = listEl.querySelector<HTMLLIElement>('[data-permission-template="true"]')!

  const item = template.cloneNode(true) as HTMLLIElement
  const nameEl = document.createElement('strong')
  const levelEl = document.createElement('strong')
  nameEl.textContent = permissionName
  levelEl.textContent = `Access: ${permissionLevel}`
  item.hidden = false
  item.setAttribute('data-permission-template', 'false')
  item.append(nameEl, levelEl)
  listEl.appendChild(item)
}

function formatDateString(date: Date): string {
  return new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })
    .format(date)
    .replace(/,(?=\s\d{4})/, '')
}

export function calculateDate(expirationValue: string): string {
  const date = new Date()
  date.setDate(date.getDate() + Number(expirationValue))
  return formatDateString(date)
}
