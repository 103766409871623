export const enum RepoSelectionType {
  None = 'none',
  All = 'all',
  Selected = 'selected',
}

export enum PermissionType {
  Organization = 'organization',
  Repository = 'repository',
  User = 'user',
  Business = 'business',
}

export function updateElText(selector: string, text: string) {
  const els = document.querySelectorAll<HTMLElement>(selector)
  for (const el of els) {
    el.textContent = text
  }
}
