import {debounce} from '@github/mini-throttle'
import {onInput} from '@github-ui/onfocus'
import {remoteForm} from '@github/remote-form'
import {requestSubmit} from '@github-ui/form-utils'

remoteForm('.js-app-bgcolor-form', async function (form, wants) {
  const noticeContainer = form.querySelector('.js-app-bgcolor-save-notice')
  if (!noticeContainer) {
    return
  }

  let response
  try {
    response = await wants.html()
  } catch (responseError) {
    noticeContainer.classList.remove('visible')
  }

  if (response) {
    noticeContainer.classList.add('visible')
    setTimeout(() => noticeContainer.classList.remove('visible'), 1500)
  }
})

function updateLogoBackgroundColor(container: Element, bgcolor: string) {
  const bgcolorEl = container.querySelector('.js-app-logo-with-bgcolor')

  if (bgcolorEl instanceof HTMLElement) {
    bgcolorEl.style.backgroundColor = `#${bgcolor}`
  }
}

function saveLogoBackgroundColor(form: HTMLFormElement, input: HTMLInputElement) {
  if (input.checkValidity()) {
    requestSubmit(form)
  }
}

onInput('.js-app-bgcolor-input', function (event) {
  const input = event.target
  if (!(input instanceof HTMLInputElement)) return
  const form = input.closest<HTMLFormElement>('form')!
  const color = input.value.replace(/^#/, '')

  if (color.length < 1) {
    input.classList.remove('color-fg-danger')
    updateLogoBackgroundColor(form, input.defaultValue)
    return
  }

  if (input.checkValidity()) {
    input.classList.remove('color-fg-danger')
    updateLogoBackgroundColor(form, color)

    if (form.classList.contains('js-app-bgcolor-form')) {
      debounce(() => saveLogoBackgroundColor(form, input), 400)()
    }
  } else {
    input.classList.add('color-fg-danger')
    updateLogoBackgroundColor(form, input.defaultValue)
  }
})
